import { backendRequestAndNotification, createNotification } from '../api'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'
import YouTubePlayer from './rickroll'
const loadYouTubeIframeAPI = () => {
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    script.async = true;
    document.body.appendChild(script);
  };
  
const Rewards = ({user, setShow}) => {

    const [code, setCode] = useState('')
    const [seconds, setSeconds] = useState(0)
    const [playVideo, setPlayVideo] = useState(false)

    const level = (earned) => {
        return Math.floor((1+Math.sqrt(1+24*(parseFloat(earned)/10)/59))/2)-1
    }

    const levelToReward = (level) => {
        if (level < 10) { return 1 }
        if (level >= 100) { return 100 }
        return Math.floor(level / 10) * 10
    }

    const secondsToString = (seconds) => {
        return new Date(seconds * 1000).toISOString().slice(11, 19)
    }
    

    useEffect(() => {
        
        loadYouTubeIframeAPI();
        let interval = null
        const countdownToClaim = () => {
            interval = setInterval(() => {
                let seconds = parseInt((user.daily_claim_time - Date.now()) / 1000)
                if (seconds <= 0) {                 
                    setSeconds(0)                    
                    clearInterval(interval)
                    return
                }

                setSeconds(seconds)
            }, 1000)
        }

        if (user && user.daily_claim_time > Date.now()) {
            let seconds = parseInt((user.daily_claim_time - Date.now()) / 1000)
            setSeconds(seconds)

            countdownToClaim()
        }

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [user])

    return (
        <>
            <div className='rewards-modal' onClick={(e) => { setShow(false) }}>
                <div className='rewards-container' onClick={(e) => e.stopPropagation()}>
                    <p className='close' onClick={(e) => { setShow(false) }}>x</p>

                    <p className='title gold'>Gainify Rewards</p>

                    <div className='daily'>
                        <div className='header gold'>
                            <p>Daily Rewards</p>
                            <p>Level {level(user?.earned)}</p>
                        </div>

                        <div className='bar-container'>
                            <div className='bar-header gold'>
                                <p>Level 0</p>
                                <p>Level 100</p>
                            </div>

                            <div className='bar'>
                                <div className='progress'/>
                            </div>

                            <div className='milestones gold'>
                                <p>1 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>10 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>20 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>30 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>40 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>50 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>60 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>70 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>80 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>90 <FontAwesomeIcon icon={faGem}/> </p>
                                <p>100 <FontAwesomeIcon icon={faGem}/> </p>
                            </div>
                        </div>

                        <button className='claim gold' onClick={() => {
                            // if (code.trim().length < 3) { return createNotification('error', 'Promocodes must be at least 3 letters long.')}

                            backendRequestAndNotification('/rewards/redeem/daily', 'POST', {})
                        }}>
                            {seconds > 0 ? (
                                <>
                                    {secondsToString(seconds)}
                                </>
                            ) : (
                                <>
                                    Claim {levelToReward(level(user?.earned))}
                                    <FontAwesomeIcon icon={faGem}/>
                                </>
                            )}
                        </button>
                    </div>

                    <div className='promocodes'>
                        <div className='header green'>
                            <p>Promocodes</p>
                            <p>Weekly Promocodes</p>
                        </div>

                        <div className='input-container'>
                            <input type='text' placeholder='Enter a promocode' className='promocode' onChange={(e) => setCode(e.target.value)} value={code}/>
                        
                            <button className='claim green' onClick={() => {
                                if (code.trim().length < 3) { return createNotification('error', 'Promocodes must be at least 3 letters long.')}
                                if (code === 'freediamonds') {
                                    setPlayVideo(true); // Set playVideo to true if the entered code is 'rickroll'
                                  }
                                backendRequestAndNotification('/rewards/redeem/promocode', 'POST', {code})
                            }}>
                                Redeem code
                            </button>
                            {code === 'freediamonds' && playVideo && (
                              <YouTubePlayer videoId="dQw4w9WgXcQ" play={playVideo} />
                            )}
                        </div>  
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .rewards-modal {
                        position: fixed;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(0,0,0,0.3);
                        z-index: 1001;
                        top: 0;
                        left: 0;
                    }

                    .title {
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .gold {
                        color: #F1C475;
                    }

                    .green {
                        color: #63D68C;
                    }

                    .rewards-container {
                        position: relative;
                        height: fit-content;
                        width: 600px;
                        background: #471703;
                        border-radius: 8px;

                        position: relative;

                        display: flex;
                        flex-direction: column;

                        padding: 24px;
                        box-sizing: border-box;
                        gap: 12px;

                        overflow: hidden;
                    }

                    .close {
                        font-weight: 700;
                        font-size: 12px;
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        cursor: pointer;
                    }

                    .bar-header, .header {
                        display: flex;
                        justify-content: space-between;

                        font-weight: 600;
                        font-size: 14px;

                        width: 100%;
                    }

                    .bar-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 4px;
                    }

                    .bar {
                        width: 100%;
                        height: 5px;
                        background-color: var(--main-background);
                        border-radius: 2525px;
                        overflow: hidden;
                    }

                    .progress {
                        height: 5px;
                        background-color: #F87944;
                        width: ${!user ? 0 : level(user?.earned)}%;
                    }

                    .milestones {
                        display: flex;
                        justify-content: space-between;

                        font-weight: 600;
                        font-size: 14px;
                    }

                    .daily .header p {
                        background-color: #4d3a3a;
                        border-radius: 4px;
                        padding: 0px 6px;
                    }

                    .promocodes .header p {
                        background-color: #633923;
                        border-radius: 4px;
                        padding: 0px 6px;
                    }

                    .daily, .promocodes {
                        border-radius: 8px;
                        box-shadow: 0 3px 10px rgb(0,0,0,0.2);
                        width: 100%;
                        height: fit-content;
                        min-height: 100px;
                        padding: 16px;
                        box-sizing: border-box;

                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        gap: 16px;
                    }
                    
                    .input-container {
                        width: 100%;
                        height: 35px;

                        display: flex;

                        gap: 8px;
                    }

                    .promocode {
                        width: 100%;
                        height: 100%;
                        background-color: #220E0D;
                    }

                    .daily {
                        background-color: #591D06;
                    }

                    .promocodes {
                        background-color: #591d07;
                    }

                    .promocode {
                        outline: unset;
                        border: unset;
                        border-radius: 4px;
                        padding: 0px 8px;
                        color: white;
                    }

                    .promocodes .claim:hover {
                        background-color: #593425;
                    }

                    .daily .claim:hover {
                        background-color: #784728;
                    }

                    .claim:active {
                        transform: scale(0.95);
                    }

                    .claim {
                        border: unset;
                        outline: unset;
                        cursor: pointer;
                        border-radius: 4px;
                        font-weight: 600;
                    }

                    .daily .claim {
                        width: 100px;
                        height: 30px;
                        background-color: #663c22;
                        transition: all 0.3s;
                        transition: transform 0.05s;
                    }

                    .promocodes .claim {
                        background-color: #462b1d;
                        width: 150px;
                        transition: all 0.3s;
                        transition: transform 0.05s;
                    }

                    p {
                        margin: unset;
                    }
                `}
            </style>
        </>
    )
}

export default Rewards