const Offerwall = ({img, bg, active, click, disabled}) => {
    return (
        <>
            <div className={'wall-container ' + (active ? 'active' : '')} onClick={disabled ? null : click}>
                <img src={img} alt='' draggable='false'/>
                
                <div className={'disabled ' + (disabled ? 'active' : '')}>
                    <p>Temporarily Disabled</p>
                </div>
            </div>

            <style jsx>
                {`
                    .wall-container {
                        height: 95px;
                        background: ${bg};
                        border-radius: 6px;

                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        cursor: pointer;
                        transition: transform .5s;

                        overflow: hidden;
                    }

                    .wall-container:hover {
                        transform: scale(0.98);
                    }

                    img {
                        width: 120px;
                        user-select: none;
                    }

                    .wall-container.active {
                        border: 4px solid rgb(77, 169, 248);
                    }

                    .disabled {
                        display: none;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: rgba(0,0,0,.8);
                        font-weight: 600;
                        font-size: 1rem;
                        color: var(--highlight-color);

                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        user-select: none;
                    }

                    .disabled.active {
                        display: flex;
                    }
                `}
            </style>
        </>
    )
}

export default Offerwall