import { toast } from 'react-toastify';

const sendNotification = (type, message) => {
    let content = {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'colored',
    }

    if (!type) {
        return toast(message, content)
    }

    if (type === 'error') { 
        return toast.error(message, content)
    }

    if (type === 'success') {
        return toast.success(message, content)
    }
}

export async function backendRequest(path, method, body) {
    try {

        const res = await fetch(process.env.REACT_APP_SERVER_URL + path, {
            method: method,
            body: body ? JSON.stringify(body) : null,
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        const data = await res.json()
        
        return data;
    } catch (e) {
        console.log(`Error when trying to make a ${method} request to ${path} `, e)
        return null
    }
}

export async function backendRequestAndNotification(path, method, body) {
    try {

        const res = await fetch(process.env.REACT_APP_SERVER_URL + path, {
            method: method,
            body: body ? JSON.stringify(body) : null,
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        const data = await res.json()

        sendNotification(data.type, data.message);
        
        return data;
    } catch (e) {
        console.log(`Error when trying to make a ${method} request to ${path} `, e)
        return null
    }
}

export function createNotification(type, message) {
    sendNotification(type, message);
}