import { useState } from "react";

const Answers = () => {
  const [tab, setTab] = useState(0);

  return (
    <>
      <div className="answer-container">
        <div className="questions">
          <div
            className={"question " + (tab === 0 ? "active" : "")}
            onClick={() => setTab(0)}
          >
            <p>Notik.me</p>
          </div>

          <div
            className={"question " + (tab === 1 ? "active" : "")}
            onClick={() => setTab(1)}
          >
            <p>AyetStudios</p>
          </div>

          <div
            className={"question " + (tab === 2 ? "active" : "")}
            onClick={() => setTab(2)}
          >
            <p>Monlix</p>
          </div>

          <div
            className={"question " + (tab === 3 ? "active" : "")}
            onClick={() => setTab(3)}
          >
            <p>AdGateMedia</p>
          </div>

          <div
            className={"question " + (tab === 4 ? "active" : "")}
            onClick={() => setTab(4)}
          >
            <p>MMWall</p>
          </div>

          <div
            className={"question " + (tab === 5 ? "active" : "")}
            onClick={() => setTab(5)}
          >
            <p>TimeWall</p>
          </div>
        </div>

        <div className="questions">
          <div
            className={"question " + (tab === 6 ? "active" : "")}
            onClick={() => setTab(6)}
          >
            <p>Lootably</p>
          </div>

          <div
            className={"question " + (tab === 7 ? "active" : "")}
            onClick={() => setTab(7)}
          >
            <p>Cheddar.TV</p>
          </div>

          <div
            className={"question " + (tab === 8 ? "active" : "")}
            onClick={() => setTab(8)}
          >
            <p>CPXResearch</p>
          </div>

          <div
            className={"question " + (tab === 9 ? "active" : "")}
            onClick={() => setTab(9)}
          >
            <p>Adgem</p>
          </div>

          <div
            className={"question " + (tab === 10 ? "active" : "")}
            onClick={() => setTab(10)}
          >
            <p>RevU</p>
          </div>

          <div
            className={"question " + (tab === 11 ? "active" : "")}
            onClick={() => setTab(11)}
          >
            <p>Torox</p>
          </div>

          <div
            className={"question " + (tab === 12 ? "active" : "")}
            onClick={() => setTab(12)}
          >
            <p>Inbrain.ai</p>
          </div>

          <div
            className={"question " + (tab === 13 ? "active" : "")}
            onClick={() => setTab(13)}
          >
            <p>Bitlabs</p>
          </div>
        </div>

        <div className="answer">
          {tab === 0 ? (
            <p>
              If you wish to contact Notik support in any other
              way, use their email:{" "}
              <a href="mailto:support@notik.me">support@notik.me</a> - don't
              forget to include your Gainify UserID when creating a ticket.
            </p>
          ) : tab === 1 ? (
            <p>
              Navigate to the AyeTStudios wall and click the three lines at the
              top left section of the wall. Then, click "Reward Status" and
              select "Pending". Click the offer you have completed and scroll to
              Support. Click the "Submit Support Ticket" hyperlink.
            </p>
          ) : tab === 2 ? (
            <p>
              Navigate to the Monlix wall and click the three lines at the top
              right section of the site. Then, click "Contact" and fill out the
              form. Don't forget to include your Gainify UserID when creating a
              ticket with them.
            </p>
          ) : tab === 3 ? (
            <p>
              Navigate to the AdGateMedia wall and click the three lines at the
              top left section of the wall. Then, click "Reward Status" and
              select "Pending". Click the offer you completed and press
              "Contact".
            </p>
          ) : tab === 4 ? (
            <p>
              Navigate to the MMWall wall and click the clock icon at the bottom
              of the tab. Click the offer you completed, and then click the
              message box at the top of the tab in the yellow section.
            </p>
          ) : tab === 5 ? (
            <p>
              Navigate to the Timewall site and click the chat icon at the
              bottom right of the tab. Please note that if you did an offer on
              TimeWall, you'll need to follow the same steps if it was an
              offerwall. We cannot help with their tasks, so please contact
              their support.
            </p>
          ) : tab === 6 ? (
            <p>
              Navigate to the Lootably wall, click the "Support" tab, find your
              offer, copy the relevant information, and then email Lootably
              support at{" "}
              <a href="mailto:support@lootably.com">support@lootably.com</a> -
              do not forget to include the ID listed at the bottom of the
              "Recent Offers:" title.
            </p>
          ) : tab === 7 ? (
            <p>
              To contact Cheddar.TV support, you'll need to email{" "}
              <a href="mailto:support@cheddar.tv">support@cheddar.tv</a>, there is no
              official support tab on Cheddar.tv, include all relevant details that
              may have caused no credit to your Gainify account.
            </p>
          ) : tab === 8 ? (
            <p>
              Navigate to the CPXResearch wall, click the 3 lines in the top
              left of the wall, and then click the "Help" tab, scroll to the
              bottom and fill in the relevant information.
            </p>
          ) : tab === 9 ? (
            <p>
              Navigate to the Adgem wall, click the "Reward Status" tab, and
              then click "View" on the offer you completed, then click the
              "Contact Support" button at the bottom, ensure you provide all
              information they request.
            </p>
          ) : tab === 10 ? (
            <p>
              Navigate to the RevU wall, click the headphone emoji next to your
              earnings, find the offer you completed, then click the "Contact
              Support" button hyperlink at the end of the offer table, provide
              all proof of completion to them.
            </p>
          ) : tab === 11 ? (
            <p>
            Navigate to the ToroX wall, click the "My Activity" button in the middle of the screen, find the offer and then click "Missing Reward".
            </p>
          ) : tab === 12 ? (
            <p>
              You can only contact Inbrain.ai support via their official email
              at <a href="mailto:support@inbrain.ai">support@inbrain.ai</a>,
              don't forget to include your Gainify UserID when creating an
              email to them.
            </p>
          ) : tab === 13 ? (
            <p>
              Unfortunately at this stage Bitlabs seems to have ended their user
               facing support and the email no longer works. We will update this section
                if a way to contact them becomes available again in future.
            </p>
          ) : null}
        </div>
      </div>

      <style jsx>{`
        .answer-container {
          width: 100%;
          background-color: var(--dark-background);
          border-radius: 12px;
          padding: 15px;
        }

        .questions {
          width: 100%;
          height: 60px;
          transition: all 0.3s ease-in-out;
          border-radius: 6px;

          display: flex;
          gap: 15px;

          cursor: pointer;

          margin-top: 15px;
        }

        .questions:first-child {
          margin: unset;
        }

        .question {
          height: 100%;
          flex: 1;
          background-color: var(--secondary-background);
          color: var(--text-color);
          box-shadow: 0 3px 10px rgb(0 0 0 / 20%);

          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;

          text-align: center;
          font-weight: 600;
          font-size: 14px;
          border-radius: 6px;

          transition: all 0.2s;
          user-select: none;
        }

        .question:hover,
        .active {
          background-color: var(--secondary-background-active);
        }

        .answer {
          padding: 15px;
          font-size: 15px;
          color: #a1adc5;
          font-weight: 600;
        }
      `}</style>
    </>
  );
};

export default Answers;
