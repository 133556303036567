import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Tab = ({title, icon, stat}) => {
    return (
        <>
            <div className='tab-container'>
                <p className='title'>{title?.split(' ')[0]} <span className='blue'>{title?.split(' ')[1]}</span></p>

                <div className='info'>
                    <FontAwesomeIcon icon={icon}/>
                    <p>{stat}</p>
                </div>
            </div>

            <style jsx>
                {`
                    .tab-container {
                        flex: 1;
                        min-height: 100px;
                        height: 100px;
                        background-color: var(--dark-background);
                        border-radius: 8px;
                        background: rgba(9, 13, 21, 0.28);
                        border: 2px solid #512c02;

                        gap: 10px;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        min-width: 220px;
                    }

                    p {
                        margin: unset;
                    }

                    .title {
                        font-weight: 600;
                        font-size: 20px;
                    }

                    .blue {
                        color: var(--highlight-color)
                    }

                    .info {
                        font-weight: 600;
                        font-size: 26px;

                        align-items: center;
                        display: flex;
                        gap: 10px;
                    }
                `}
            </style>
        </>
    )
}

export default Tab