import { faQuestionCircle, faHandHoldingDollar, faShoppingCart, faToolbox, faMailForward, faHeadset } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import Question from '../components/help/question'

import Earning from '../components/help/earning'
import Withdawing from '../components/help/withdrawing'
import Issues from '../components/help/issues'
import ContactingWalls from '../components/help/contactwalls'
import ContactingSupport from '../components/help/contactsupport'

const Help = () => {

    const [tab, setTab] = useState(0)

    return (
        <>
            <div className='help-container'>
                <div className='title'>
                    <span className='blue'><FontAwesomeIcon icon={faQuestionCircle}/></span>
                    <p>Help</p>
                </div>

                <div className='qna'>
                    <div className='questions'>
                        <Question icon={faHandHoldingDollar} question='Earning Diamonds' active={tab === 0} click={() => setTab(0)}/>
                        <Question icon={faShoppingCart} question='Withdrawing Diamonds' active={tab === 1} click={() => setTab(1)}/>
                        <Question icon={faToolbox} question='Website Issues' active={tab === 2} click={() => setTab(2)}/>
                        <Question icon={faMailForward} question='Contacting Offerwalls' active={tab === 3} click={() => setTab(3)}/>
                        <Question icon={faHeadset} question='Contacting Support' active={tab === 4} click={() => setTab(4)}/>
                    </div>
                    
                    {tab === 0 ? (
                        <Earning/>
                    ) : tab === 1 ? (
                        <Withdawing/>
                    ) : tab === 2 ? (
                        <Issues/>
                    ) : tab === 3 ? (
                        <ContactingWalls/>
                    ) : tab === 4 ? (
                        <ContactingSupport/>
                    ) : null}
                </div>
            </div>

            <style jsx>{`
                .help-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;

                    padding: 50px 200px;
                    gap: 25px;

                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .help-container::-webkit-scrollbar {
                    display: none;
                }

                .title {
                    font-weight: 500;
                    font-size: 24px;

                    gap: 10px;
                    display: flex;
                    align-items: center;
                }

                .title p {
                    margin: unset;
                }

                .blue {
                    color: var(--highlight-color);
                }

                .qna {
                    display: flex;
                    gap: 15px;
                }

                .questions {
                    width: 300px;
                    min-width: 300px;
                    height: fit-content;
                    border-radius: 12px;
                    background-color: var(--dark-background);

                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    padding: 15px;
                }

                @media only screen and (max-width: 1690px) {
                    .help-container {
                        padding: 50px 75px;
                    }
                }

                @media only screen and (max-width: 1200px) {
                    .help-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 1100px) {
                    .help-container {
                        padding: 50px 25px;
                    }

                    .questions {
                        width: 100%;
                    }

                    .qna {
                        flex-direction: column;
                    }
                }

                @media only screen and (max-width: 290px) {
                    .help-container {
                        padding: 50px 0px;
                    }
                }
            `}</style>
        </>
    )
}

export default Help