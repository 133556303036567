import { useState } from "react"

const Answers = () => {

    const [tab, setTab] = useState(0)

    return (
        <>
            <div className='answer-container'>
                <div className='questions'>
                    <div className={'question ' + (tab === 0 ? 'active' : '')} onClick={() => setTab(0)}>
                        <p>How do I use the Referral system?</p>
                    </div>

                    <div className={'question ' + (tab === 1 ? 'active' : '')} onClick={() => setTab(1)}>
                        <p>How can I get unbanned/unmuted from the support?</p>
                    </div>

                    <div className={'question ' + (tab === 2 ? 'active' : '')} onClick={() => setTab(2)}>
                        <p>How do I claim my leaderboard reward?</p>
                    </div>
                </div>

                <div className='answer'>
                    {tab === 0 ? (
                        <p>
                            The Gainify affiliate system involves sharing your referral code, which can be customized <a href="https://gainify.net/profile">here</a>, for new users to input when they join our site. If a user uses your code when signing up they will receive 100 free Diamonds ($0.1) as a reward for joining the site. From then on, you will get an extra 5% of whatever they earn on Gainify - forever!
                        </p>
                    ) : tab === 1 ? (
                        <>
                            <p>
                            If you were banned from the website/support chat, please use the following options:
                            </p>

                            <ul>
                                <li>For users that were banned from the Support Chat, you may contact us via email <a href="mailto:support@grindbux.com">here</a>.</li>
                                <li>For users that were banned from the main website chat, you can request an unban by contacting our on site support.</li>    
                            </ul>
                        </>
                    ) : tab === 2 ? (
                        <p>
                            Your rewards will automatically be placed in your account upon the daily leaderboard reset. (Midnight UTC)
                            Monthly rewards will be placed into your account automatically at the end of the month. (Midnight UTC on the last day of the month)
                        </p>
                    ) : null}
                </div>
            </div>

            <style jsx>{`
                .answer-container {
                    width: 100%;
                    background-color: var(--dark-background);
                    border-radius: 12px;
                    padding: 15px;
                }

                .questions {
                    width: 100%;
                    height: 60px;
                    transition: all 0.3s ease-in-out;
                    border-radius: 6px;

                    display: flex;
                    gap: 15px;

                    cursor: pointer;
                }

                .question {
                    height: 100%;
                    flex: 1;
                    background-color: var(--secondary-background);
                    color: var(--text-color);
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    border-radius: 6px;

                    transition: all .2s;
                    user-select: none;
                }

                .question:hover, .active {
                    background-color: var(--secondary-background-active);
                }

                .answer {
                    padding: 15px;
                    font-size: 15px;
                    color: #a1adc5;
                    font-weight: 600;
                }
            `}</style>
        </>
    )
}

export default Answers
