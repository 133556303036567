import { useEffect, useState } from 'react'


import Pages from '../../all/pages'
//import { backendRequest } from '../../api'


const Offers = ({userid}) => {

    const [offers, setOffers] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

    useEffect(() => {
        async function getOffers() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/offers/${userid}`, { credentials: 'include' });
                const data = await res.json()
            
                setOffers(data)
            } catch (e) {
                setOffers([])
                return
            }
        }
        
        getOffers()
    }, [realPage, userid])
    
    const dynamicallyLoadMorePages = async () => {

        let currentTotalPages = Math.round(offers.length / 8)

        if (currentTotalPages - 3 === fakePage) {
            setRealPage(realPage + 1)

            const data = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/offers/${userid}`, { credentials: 'include' })
            setOffers([...offers, ...data])

        }
    }

    return (
        <>
            <div className='users-container'>
                <div className='table-header'>
                    <p>Date</p>
                    <p>Status</p>
                    <p>Wall</p>
                    <p>IP</p>
                    <p>Diamonds</p>
                    <p>Offer ID</p>
                    <p>Offer Name</p>
                </div>

                {Array.isArray(offers) && offers.slice(fakePage * 8, (fakePage + 1) * 8).map((offer, index) => (
                    <div className='table-row' key={offer._id}>
                        <div className='date'>
                            <p>{new Date(offer?.timestamp).toLocaleDateString()}</p>

                            <p className='date-hover'>
                                {new Date(offer?.timestamp).toLocaleTimeString()}
                            </p>
                        </div>
                        <p>{offer?.status}</p>
                        <p>{offer?.wall}</p>
                        <p className='ip clickable' onClick={() => window.open(`https://www.ipqualityscore.com/free-ip-lookup-proxy-vpn-test/lookup/${offer?.user_ip}`, '_blank')}>{offer?.user_ip}</p>
                        <p>{offer?.points}</p>
                        <p>{offer?.offer_id}</p>
                        <p>{offer?.offer_name}</p>
                    </div>
                ))}

                <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(offers?.length / 8)} curpage={fakePage} setPage={setFakePage}/>

            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-header, .table-row {
                    background-color: var(--dark-background);
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;

                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .date {
                    cursor: pointer;
                    position: relative;
                    overflow: visible !important;
                }

                .date-hover {
                    top: 30px;
                    margin: 0px auto;
                    left: 0;
                    right: 0;
                    position: absolute;
                    display: none;

                    width: fit-content;
                    text-align: center;
                }

                .date:hover .date-hover {
                    display: block;
                }
            `}</style>
        </>
    )
}

export default Offers
