import React, { useState, useEffect } from 'react';

// CSS for Spinning Animation
const css = `
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .wheel-container {
    width: 300px;
    height: 300px;
    position: relative;
  }

  .wheel {
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    animation: spin 5s linear infinite;
  }

  .segment {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const Wheel = () => {
  const [spinning, setSpinning] = useState(false);
  const [prizes, setPrizes] = useState([]);
  const [newPrize, setNewPrize] = useState('');
  const [newChance, setNewChance] = useState('');

  useEffect(() => {
    const wheel = document.querySelector('.wheel');
    if (wheel) {
      const segments = document.querySelectorAll('.segment');
      const segmentAngle = 360 / prizes.length;

      // Update the rotation angle for each segment
      segments.forEach((segment, index) => {
        const rotation = index * segmentAngle;
        segment.style.transform = `rotate(${rotation}deg) skewY(${segmentAngle}deg)`;
      });

      // Reset the animation by removing and re-adding the animation class
      wheel.classList.remove('spin');
      void wheel.offsetWidth; // Trigger reflow
      wheel.classList.add('spin');
    }
  }, [prizes]);

  const spinWheel = () => {
    if (!spinning && prizes.length > 0) {
      const totalChances = prizes.reduce((acc, prize) => acc + prize.chance, 0);
      if (totalChances !== 100) {
        alert('Total chances should add up to 100%. Please adjust the chances.');
        return;
      }

      // Generate a random angle for spinning
      const randomAngle = Math.floor(Math.random() * 360) + 720;
      setSpinning(true);

      setTimeout(() => {
        // After animation completes, set spinning back to false
        setSpinning(false);

        // Calculate the prize based on the angle and chances
        let cumulativeChance = 0;
        const randomValue = Math.random() * 100;
        let selectedPrize = null;

        for (const prize of prizes) {
          cumulativeChance += prize.chance;
          if (randomValue <= cumulativeChance) {
            selectedPrize = prize;
            break;
          }
        }

        alert(`You won: ${selectedPrize.name}`);
      }, 5000); // Adjust the duration to match the animation duration
    } else if (prizes.length === 0) {
      alert('Please add prizes before spinning.');
    }
  };

  const addPrize = () => {
    if (newPrize.trim() !== '' && newChance !== '') {
      const chance = parseFloat(newChance);
      if (isNaN(chance) || chance < 0 || chance > 100) {
        alert('Please enter a valid chance between 0 and 100.');
        return;
      }

      setPrizes([...prizes, { name: newPrize, chance }]);
      setNewPrize('');
      setNewChance('');
    }
  };

  return (
    <div>
      <style>{css}</style>
      <div>
        <div className="wheel-container">
          <div className={`wheel ${spinning ? 'spin' : ''}`}>
            {prizes.map((prize, index) => (
              <div
                key={index}
                className="segment"
                style={{
                  borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color for each segment
                }}
              >
                {prize.name}
              </div>
            ))}
          </div>
        </div>
        <input
          type="text"
          placeholder="Enter a prize"
          value={newPrize}
          onChange={(e) => setNewPrize(e.target.value)}
        />
        <input
          type="number"
          placeholder="Enter chance (%)"
          value={newChance}
          onChange={(e) => setNewChance(e.target.value)}
        />
        <button onClick={addPrize} disabled={spinning}>
          Add Prize
        </button>
        <button onClick={spinWheel} disabled={spinning || prizes.length === 0}>
          {spinning ? 'Spinning...' : 'Spin the Wheel'}
        </button>
      </div>
    </div>
  );
};

export default Wheel;