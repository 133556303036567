const Carousel = ({children}) => {
    return (
        <>
            <div className='tab-container'>
                {children}
            </div>

            <style jsx>
                {`
                    .tab-container {
                        width: 100%;
                        display: flex;
                        gap: 15px;
                        flex-wrap: wrap;
                    }
                `}
            </style>
        </>
    )
}

export default Carousel