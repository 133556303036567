import { useEffect, useState } from 'react'

import Pages from '../../all/pages'
//import { backendRequest } from '../../api'

const Reversals = ({ userid }) => {
  const [reversedOffers, setReversedOffers] = useState([])
  // eslint-disable-next-line
  const [realPage, setRealPage] = useState(0)
  // eslint-disable-next-line
  const [fakePage, setFakePage] = useState(0)

  useEffect(() => {
    async function getReversedOffers() {
      try {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/reversals/${userid}`, { credentials: 'include' });
        const data = await res.json()
        setReversedOffers(data)
      } catch (e) {
        setReversedOffers([])
        return
      }
    }
    getReversedOffers()
  }, [realPage, userid])

  const dynamicallyLoadMorePages = async () => {
    let currentTotalPages = Math.round(reversedOffers.length / 8)

    if (currentTotalPages - 3 === fakePage) {
      setRealPage(realPage + 1)

      const data = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/reversals/${userid}`, { credentials: 'include' })
      const newData = await data.json()

      setReversedOffers([...reversedOffers, ...newData])
    }
  }

  return (
    <>
      <div className='reversed-offers-container'>
        <div className='table-header'>
          <p>Date</p>
          <p>Status</p>
          <p>Wall</p>
          <p>IP</p>
          <p>Diamonds</p>
          <p>Offer ID</p>
          <p>Offer Name</p>
        </div>

        {Array.isArray(reversedOffers) &&
          reversedOffers.slice(fakePage * 8, (fakePage + 1) * 8).map((reversedOffer, index) => (
            <div className='table-row' key={reversedOffer._id}>
              <div className='date'>
                <p>{new Date(reversedOffer?.timestamp).toLocaleDateString()}</p>

                <p className='date-hover'>
                  {new Date(reversedOffer?.timestamp).toLocaleTimeString()}
                </p>
              </div>
              <p>{reversedOffer?.status}</p>
              <p>{reversedOffer?.wall}</p>
              <p className='ip clickable' onClick={() => window.open(`https://www.ipqualityscore.com/free-ip-lookup-proxy-vpn-test/lookup/${reversedOffer?.user_ip}`, '_blank')}>{reversedOffer?.user_ip}</p>
              <p>{reversedOffer?.points}</p>
              <p>{reversedOffer?.offer_id}</p>
              <p>{reversedOffer?.offer_name}</p>
            </div>
          ))}

        <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(reversedOffers?.length / 8)} curpage={fakePage} setPage={setFakePage} />
      </div>

      <style jsx>{`
        .reversed-offers-container {
          width: 100%;
        }

        .title {
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          color: #F1C475;
          margin: unset;
        }

        .table-header, .table-row {
          background-color: var(--dark-background);
          display: flex;
          border-radius: 12px;
          padding: 0px 15px;
          font-size: 14px;
          align-items: center;
        }

        .table-row {
          margin-top: 15px;
          transition: all .2s;
        }

        .table-row > * {
          flex: 1;
          text-align: center;

          overflow: hidden;
          text-overflow: ellipsis;
        }

        .table-header > * {
          flex: 1;
          text-align: center;
          opacity: 0.5;
        }

        .date {
          cursor: pointer;
          position: relative;
          overflow: visible !important;
        }

        .date-hover {
          top: 30px;
          margin: 0px auto;
          left: 0;
          right: 0;
          position: absolute;
          display: none;

          width: fit-content;
          text-align: center;
        }

        .date:hover .date-hover {
          display: block;
        }
      `}</style>
    </>
  )
}

export default Reversals;