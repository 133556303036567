import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

// import Daily from '../components/leaders/daily'
import Monthly from '../components/leaders/monthly'
import AllTime from '../components/leaders/alltime'


const Leaders = () => {

    const [type, setType] = useState(0)

    return (
        <>
            <div className='leaders-container'>
                <div className='title'>
                    <span className='blue'><FontAwesomeIcon icon={faTrophy}/></span>
                    <p>Leaders</p>

                    <div className='types'>
                        {/* 
                        DISABLE DAILY
                        <div className={'type ' + (type === -1 ? 'active' : '')} onClick={() => setType(-1)}>
                            <p>Daily</p>
                        </div> 
                        */}

                        <div className={'type ' + (type === 0 ? 'active' : '')} onClick={() => setType(0)}>
                            <p>Monthly</p>
                        </div>

                        <div className={'type ' + (type === 1 ? 'active' : '')} onClick={() => setType(1)}>
                            <p>All Time</p>
                        </div>
                    </div>
                </div>
                
                {/* {type === -1 ? (
                    <Daily/>
                ) : type === 0 ? (
                    <Monthly/>
                ) : (
                    <AllTime/>
                )} */}

            {type === 0 ? (
                    <Monthly/>
                ) : (
                    <AllTime/>
                )}
            </div>

            <style jsx>{`
                .leaders-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;

                    padding: 50px 200px;
                    gap: 25px;

                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .leaders-container::-webkit-scrollbar {
                    display: none;
                }

                .title {
                    width: 100%;
                    position: relative;

                    font-weight: 500;
                    font-size: 24px;

                    gap: 10px;
                    display: flex;
                    align-items: center;
                }

                .title p {
                    margin: unset;
                }

                .blue {
                    color: var(--highlight-color);
                }

                .types {
                    margin-left: auto;
                    display: flex
                }

                .type {
                    transition: all 0.3s;
                    user-select: none;
                    width: 100px;
                    height: 30px;
                    font-size: 16px;
                    background-color: var(--secondary-background);
                    color: var(--highlight-color);
                    cursor: pointer;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .type:first-child {
                    border-radius: 10px 0px 0px 10px;
                }

                .type:last-child {
                    border-radius: 0px 10px 10px 0px;
                }

                .type.active {
                    box-shadow: 0 3px 10px rgb(0, 0, 0, .2);
                    background-color: #1D3446;
                    color: #419863; 
                }

                .type:hover:not(.active) {
                    background-color: var(--secondary-background-active)
                }

                .topthree {
                    display: flex;
                    width: 100%;
                    gap: 15px;
                    
                    align-items: flex-end;
                }

                .first, .second, .third {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    gap: 15px;
                }

                .first img, .second img, .third img {
                    width: 85px;
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
                    border-radius: 10px;
                    transition: all 0.5s ease-in-out;
                }

                .first img:hover, .second img:hover, .third img:hover {
                    transform: translate(0, -4px);
                }

                .first {
                    order: 1;
                }

                .second {
                    order: 0;
                }

                .third {
                    order: 2;
                }

                .podium {
                    background-color: var(--dark-background);
                    width: 100%;
                    border-radius: 12px;
                    padding: 15px; 

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .first .podium {
                    height: 160px;
                    min-height: 160px;
                }

                .second .podium {
                    height: 130px;
                    min-height: 130px;
                }

                .third .podium {
                    height: 100px;
                    min-height: 100px;
                }

                .prize {
                    border-radius: 9px;
                    font-size: 16px;
                    color: rgb(241, 196, 117);
                    background-color: rgb(28, 35, 68);
                    padding: 3px 15px;
                    box-shadow: rgb(0 0 0 / 20%) 0px 3px 10px;
                    user-select: none;
                }

                .top {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    position: relative;
                }

                .username {
                    text-align: center;
                    width: 100%;
                    color: var(--highlight-color);
                    font-size: 24px;
                    font-weight: 600;
                }

                .place {
                    min-width: 40px;
                    min-height: 40px;
                    line-height: 40px;
                    background-color: #4D3A4B;
                    text-align: center;
                    color: #F1C475;
                    border-radius: 8px;

                    position: absolute;
                    top: 0px;
                    left: 0px;
                }

                .podium .earned {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .earned {
                    color: var(--highlight-color);
                }

                .earned span {
                    color: #F1C475;
                }
                
                p {
                    margin: unset;
                }

                @media only screen and (max-width: 1100px) {
                    .leaders-container {
                        padding: 50px 100px;
                    }
                }

                @media only screen and (max-width: 870px) {
                    .leaders-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 650px) {
                    .title {
                        flex-direction: column;
                    }

                    .types {
                        width: 100%;
                        justify-content: center;
                    }
                }

                @media only screen and (max-width: 400px) {
                    .leaders-container {
                        padding: 50px 25px;
                    }
                }

                @media only screen and (max-width: 290px) {
                    .leaders-container {
                        padding: 50px 0px;
                    }
                }
            `}</style>
        </>
    )
}

export default Leaders