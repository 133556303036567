const Slider = () => {
    return (
        <>
            <div className='loader'></div>

            <style jsx>{`
                .loader {
                    border: 4px solid var(--third-background);
                    border-radius: 50%;
                    border-top: 4px solid var(--highlight-color);
                    width: 50px;
                    height: 50px;
                    -webkit-animation: spin 2s linear infinite;
                    animation: spin 2s linear infinite;
                }
                
                @-webkit-keyframes spin {
                    0% { -webkit-transform: rotate(0deg); }
                    100% { -webkit-transform: rotate(360deg); }
                }
                
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `}</style>
        </>
    )
}

export default Slider