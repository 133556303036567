const Pages = ({curpage, setPage, maxPages, loadMorePages}) => {
    return (
        <>
            <div className='controls c'>
                <div className='left c d' onClick={() => {if (curpage > 1) setPage(curpage - 1) }}>
                    <img src='/icons/arrow.png' alt=''/>
                </div>

                <p>Page {curpage} of {maxPages}</p>
                
                <div className='right c d' onClick={() => {
                    loadMorePages()

                    if (curpage < maxPages) {
                        setPage(curpage + 1)
                    }
                }}>
                    <img src='/icons/arrow.png' alt=''/>
                </div>
            </div>

            <style jsx>{`
                .controls {
                    width: 100%;
                    min-height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    margin-top: 15px;
                }

                .left, .right {
                    border-radius: 50%;
                    margin: 0px 25px;
                    display: flex;
                    cursor: pointer;
                    user-select: none;
                    transition: all 0.5s;
                }

                .left img, .right img {
                    height: 15px;
                }

                .left:hover {
                    transform: translateX(-3px);
                }

                .right:hover {
                    transform: translateX(3px);
                }

                .left img {
                    transform: rotate(180deg);
                }
            `}</style>
        </>
    )
}

export default Pages