import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Center from './components/center'
import { ToastContainer } from 'react-toastify';

import { BrowserRouter as Router } from 'react-router-dom'

// imported for lolcode 2023 import { useEffect } from 'react'


function App() {
/* lolcode for april fools 2023
  document.body.style.rotate = "180deg"
useEffect(() => {
  let timer = []

    setTimeout(() => {
      
    document.body.style.rotate = "0deg"
      
    }, 45000)

  return () => {
    clearTimeout(timer)
  }
}, [])
*/
  return (
    <div className="App">
      <Router>
        <Center/>
        <ToastContainer/>
      </Router>
    </div>
  );
}

export default App;
