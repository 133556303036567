import { faUser, faGem } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import Orders from '../components/profile/orders.jsx'
import Completed from '../components/profile/offers.jsx'
import Held from '../components/profile/held.jsx'
import Slider from '../components/all/slider'
import Chargebacks from '../components/profile/reversed.jsx'
import { backendRequestAndNotification } from '../components/api/index.js'
import Rp from "../components/all/rp.jsx"


import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode.react';

const Profile = ({user}) => {

    const [tab, setTab] = useState(0)
    const [username, setUsername] = useState(user?.name)
    const [code, setCode] = useState(user?.affiliate_code)
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
    const toggleQRCodeModal = () => {
        setIsQRCodeModalOpen(!isQRCodeModalOpen);
      };
    return (
        <>
        <Rp user={user}/>
            <div className='profile-container'>
                <div className='title'>
                    <span className='blue'><FontAwesomeIcon icon={faUser}/></span>
                    <p>Your Profile</p>
                </div>

                <div className='top-container'>
                    <div className='top-section'>
                        <img className='avatar' src={user.avatar} alt=''/>

                        <div className='right-container'>
                            <p className='info-title'>{user.username}</p>

                            <div className='info-container'>
                                <div className='info'>
                                    <p>Balance</p>
                                    <p className='right'>{user.balance?.toFixed(0)} <FontAwesomeIcon icon={faGem}/></p>
                                </div>

                                <div className='info'>
                                    <p>Total Earned</p>
                                    <p className='right'>{user.earned?.toFixed(0)} <FontAwesomeIcon icon={faGem}/></p>
                                </div>

                                <div className='info'>
                                    <p>User ID</p>
                                    <p className='right'>{user._id}</p>
                                </div>

                                <div className='info'>
                                    <p>Platform ID</p>
                                    <p className='right'>{user.platform_id}</p>
                                </div>

                                <div className='info'>
                                    <p>Verified</p>
                                    <p className='right'>{user.verified?.toString() || 'False'}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='top-section column'>
                        <p className='info-title center'>Referral Program</p>

                        <div className='info-container'>
                            <div className='info'>
                                <p>Referred By</p>
                                <p className='right'>{user.referred_by || 'No one'}</p>
                            </div>

                            <div className='info'>
                                <p>Referral Earnings</p>
                                <p className='right'>{user.affiliate_earned} <FontAwesomeIcon icon={faGem}/></p>
                            </div>

                            <div className='info'>
                                <p>Users Referred</p>
                                <p className='right'>{user.users_referred}</p>
                            </div>

                            <div className='info'>
                                <p>Your Referral Code</p>
                                <p className='right'>{user.affiliate_code}</p>
                            </div>

                            <div className='info'>
                                <p>Your Referral Link</p>
                                <p className='right'>https://gainify.net/ref/{user.affiliate_code}</p>
                                <div className="qr-code-icon" style={{ paddingLeft: '5px', color: 'var(--highlight-color)' }} onClick={toggleQRCodeModal}>
                                    <FontAwesomeIcon icon={faQrcode} />
                                </div>
                            </div>
                            <div className='info'>
                                <p>Referral percentage</p>
                                <p className='right'>5%</p>
                            </div>
                        </div>
                    </div>
                </div>
                {isQRCodeModalOpen && (
                <div className="qr-code-modal">
                    <div className="modal-content">
                    <div className="modal-header">
                        
                        <button onClick={toggleQRCodeModal}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <QRCode value={`https://gainify.net/ref/${user.affiliate_code}`} />
                    </div>
                    </div>
                </div>
                )}
                <div className='settings-container'>
                    <div className='settings-section'>
                        <input placeholder='Change your username' value={username} onChange={(e) => setUsername(e.target.value)}/>
                        <button onClick={() => {
                            backendRequestAndNotification('/user/changename', 'POST', {name: username})
                        }}>Set Name</button>
                    </div>

                    <div className='settings-section'>
                        <input placeholder='Change your affiliate Code' value={code} onChange={(e) => setCode(e.target.value)}/>
                        <button onClick={() => {
                            backendRequestAndNotification('/user/changecode', 'POST', {code: code})
                        }}>Set Code</button>
                    </div>
                </div>

                <div className='chat-options'>
                            <div className='option'>
                                <p>Hide Level </p>

                                <Slider active={user?.hide_level || false} click={async () => {
                                    if (!user) { return }

                                    const updatedHideLevel = user.hide_level !== undefined ? !user.hide_level : true; // Toggle the boolean value or set it to false if undefined

                                    await backendRequestAndNotification('/user/hidelevel', 'POST', {
                                        userid: user._id,
                                        hide_level: updatedHideLevel,
                                    });
                                }}/>
                            </div>
                            <div className='option'>
                                <p>Hide Country</p>

                                <Slider active={user?.hide_country && true} click={async () => {
                                    if (!user) { return }

                                    const updatedHideCountry = user.hide_country !== undefined ? !user.hide_country : false; // Toggle the boolean value or set it to false if undefined

                                    await backendRequestAndNotification('/user/hidecountry', 'POST', {
                                        userid: user._id,
                                        hide_country: updatedHideCountry,
                                    });                                  
                                }}/>
                            </div>
                    </div>

                <div className='history-options'>
                    <div className={'option ' + (tab === 0 ? 'active' : '')} onClick={() => setTab(0)}>
                        <p>Withdrawals</p>
                    </div>

                    <div className={'option ' + (tab === 1 ? 'active' : '')} onClick={() => setTab(1)}>
                        <p>Completed Offers</p>
                    </div>

                    <div className={'option ' + (tab === 2 ? 'active' : '')} onClick={() => setTab(2)}>
                        <p>Held Offers</p>
                    </div>

                    <div className={'option ' + (tab === 3 ? 'active' : '')} onClick={() => setTab(3)}>
                        <p>Chargebacks</p>
                    </div>
                </div>

                {tab === 0 ? (
                    <Orders/>
                ) : tab === 1 ? (
                    <Completed/>
                ) : tab === 2 ? (
                    <Held/>
                ) : tab === 3 ? (
                    <Chargebacks/>
                ) : null}
            </div>

            <style jsx>{`
                .profile-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;

                    padding: 50px 200px;
                    gap: 25px;

                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .profile-container::-webkit-scrollbar {
                    display: none;
                }

                .title {
                    font-weight: 500;
                    font-size: 24px;

                    gap: 10px;
                    display: flex;
                    align-items: center;
                }

                .title p {
                    margin: unset;
                }

                .blue {
                    color: var(--highlight-color);
                }

                .settings-container, .top-container {
                    display: flex;
                    gap: 15px;
                    width: 100%;
                }

                .history-options {
                    height: 70px;
                    width: 100%;
                    padding: 15px;
                    border-radius: 12px;
                    background-color: var(--dark-background);
                    display: flex;
                    gap: 15px;
                }

                .chat-options {
                    height: 200px;
                    width: 100%;
                    padding: 15px;
                    border-radius: 12px;
                    background-color: var(--dark-background);
                    display: flex;
                    gap: 15px;
                }

                .option {
                    flex: 1;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: background-color .3s;
                    border-radius: 6px;
                    color: rgb(156, 164, 191);
                    user-select: none;
                    text-align: center;
                }

                .active {
                    color: #f44e09;
                    background-color: #4e1a03;
                    box-shadow: rgb(0 0 0 / 20%) 0px 3px 10px;
                }

                .option:hover {
                    background-color: var(--third-background);
                    color: white;
                }

                .settings-section {
                    display: flex;
                    flex: 1;
                    height: 75px;
                    background-color: var(--dark-background);
                    border-radius: 12px;
                    padding: 15px;
                    gap: 15px;
                }

                .settings-section input {
                    border: unset;
                    outline: unset;
                    width: 100%;
                    color: white;
                    background-color: var(--secondary-background);
                    padding: 0px 10px;
                    border-radius: 6px;
                }

                .settings-section button {
                    border: unset;
                    outline: unset;
                    width: 150px;
                    color: white;
                    background-color: var(--highlight-color);
                    border-radius: 6px;
                    cursor: pointer;
                }

                .top-section {
                    display: flex;
                    flex: 1;
                    height: 200px;
                    background-color: var(--dark-background);
                    border-radius: 12px;
                    padding: 15px;
                    gap: 15px;
                }

                .column {
                    flex-direction: column;
                }

                .avatar {
                    border-radius: 8px;
                }

                .info-title {
                    margin: unset;
                    color: #F1C475;
                    font-size: 20px;
                    font-weight: 600;
                }

                .center {
                    text-align: center;
                }

                .right-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                }

                .info-container {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    justify-content: space-evenly;
                }

                .info {
                    width: 100%;
                    height: 20px;

                    font-weight: 600;
                    font-size: 13px;

                    display: flex;
                }

                .info p {
                    margin: unset;
                }

                .right {
                    margin-left: auto !important;
                }

                @media only screen and (max-width: 1700px) {
                    .profile-container {
                        padding: 50px 100px;
                    }
                }

                @media only screen and (max-width: 1650px) {
                    .profile-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 1550px) {
                    .profile-container {
                        padding: 50px 25px;
                    }
                }

                @media only screen and (max-width: 1500px) {
                    .top-container {
                        flex-direction: column;
                    }

                    .profile-container {
                        padding: 50px 0px;
                    }
                }

                @media only screen and (max-width: 625px) {
                    .settings-container {
                        flex-direction: column;
                    }

                    .settings-section {
                        min-height: 75px;
                    }

                    .top-section {
                        height: auto;
                        flex-direction: column;
                    }

                    .top-section:not(.column) {
                        align-items: center;
                    }

                    .avatar {
                        width: 170px;
                    }
                    .qr-code-icon {
                        cursor: pointer;
                      }
                      
                      .qr-code-modal {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        z-index: 1000;
                      }
                      
                      .modal-content {
                        background-color: white;
                        border-radius: 8px;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        text-align: center;
                        padding: 20px;
                      }
                      
                      .modal-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                      }
                      
                      .modal-header h2 {
                        margin: 0;
                      }
                      
                      .modal-header button {
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        font-size: 24px;
                      }
                      
                      .modal-body {
                        margin-top: 10px;
                      }
                }
            `}</style>
        </>
    )
}

export default Profile