import { useEffect, useState } from 'react';
import Pages from '../../all/pages';

const Fingerprints = ({ userid }) => {
    const [logins, setLogins] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

  useEffect(() => {
    async function getLogins() {
      try {
        const res = await fetch(
          process.env.REACT_APP_SERVER_URL + `/admin/user/logins/${userid}`,
          { credentials: 'include' }
        );
        const data = await res.json();

        setLogins(data);
      } catch (e) {
        return;
      }
    }

    getLogins();
  }, [realPage, userid]);

  const dynamicallyLoadMorePages = async () => {
    
    let currentTotalPages = Math.round(logins.length / 8)

    if (currentTotalPages - 3 === fakePage) {
        setRealPage(realPage + 1)

        const data = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/logins/${userid}`, { credentials: 'include' });
        setLogins([...logins, ...data])

    }
  };

  return (
    <>
      <div className='users-container'>
        <div className='table-header'>
          <p>Timezone</p>
          <p>Hosting</p>
          <p>ISP</p>
          <p>GPU Vendor</p>
          <p>GPU</p>
          <p>Agent</p>
        </div>

        {Array.isArray(logins) && logins.slice(fakePage * 8, (fakePage + 1) * 8).map((login, index) => (
            <div className='table-row' key={login._id}>
              <p>{login.timezone}</p>
              <p>{login.hosting ? 'Yes' : 'No'}</p>
              <p>{login.isp}</p>
              <p>{login.gpu_vendor}</p>
              <p>{login.gpu}</p>
              <p>{login.agent}</p>
            </div>
          ))}

      
         <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(logins?.length / 8)} curpage={fakePage} setPage={setFakePage} /> 
      </div>

      <style jsx>{`
                .users-container {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-header, .table-row {
                    background-color: var(--dark-background);
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;

                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                }

                .ip {
                    cursor: pointer;
                }

                .ip:hover {
                    color: var(--highlight-color);
                }
            `}</style>
    </>
  );
};

export default Fingerprints;