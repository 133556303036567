import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pages from '../all/pages';
import { backendRequest } from '../api';

const Reversals = () => {
  const [reversedOffers, setReversedOffers] = useState([]);
  const [realPage, setRealPage] = useState(0);
  const [fakePage, setFakePage] = useState(1);

  const [lookup, setLookup] = useState('');
  const [type, setType] = useState('userid');

  const history = useNavigate();
  const redirectToPage = (url) => history('/' + url);

  useEffect(() => {
    async function getReversedOffers() {
      try {
        const data = await backendRequest('/admin/offers/reversed', 'POST', {
          page: 0,
          lookup: '',
        });
        setReversedOffers(data);
      } catch (e) {
        return;
      }
    }

    getReversedOffers();
  }, []);

  const dynamicallyLoadMorePages = async () => {
    let currentTotalPages = Math.round(reversedOffers.length / 8);

    if (currentTotalPages - 3 === fakePage) {
      setRealPage(realPage + 1);

      const data = await backendRequest('/admin/offers/reversed', 'POST', {
        page: realPage + 1,
        lookup: lookup,
        type: type,
      });
      setReversedOffers([...reversedOffers, ...data]);
    }
  };

  return (
    <>
      <div className='reversed-offers-container'>
        <div className='search'>
          <input
            placeholder='Type in lookup'
            value={lookup}
            onChange={(e) => setLookup(e.target.value)}
          />
          <button
            onClick={async () => {
              const data = await backendRequest('/admin/offers/reversed', 'POST', {
                page: realPage,
                lookup: lookup,
                type: type,
              });
              setReversedOffers(data);
              setRealPage(0);
              setFakePage(1);
            }}
          >
            Search
          </button>
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value='userid'>User ID</option>
            <option value='username'>User Name</option>
            <option value='offername'>Offer Name</option>
            <option value='offerid'>Offer ID</option>
            <option value='status'>Status</option>
            <option value='ip'>IP</option>
          </select>
        </div>

        <div className='table'>
          <div className='table-header'>
            <p>Username</p>
            <p>Wall</p>
            <p>Amount</p>
            <p>Offer Name</p>
            <p>Offer ID</p>
            <p>Status</p>
            <p>IP</p>
          </div>

          {Array.isArray(reversedOffers) &&
            reversedOffers
              .slice((fakePage - 1) * 8, fakePage * 8)
              .map((reversedOffer, index) => (
                <div className='table-row' key={reversedOffer._id}>
                  <div
                    className='name clickable'
                    onClick={() =>
                      redirectToPage(`admin/users/${reversedOffer.userid}/offers`)
                    }
                  >
                    <img className='avatar' src={reversedOffer.avatar} alt='' />
                    <p>{reversedOffer.username}</p>
                  </div>
                  <p>{reversedOffer.wall}</p>
                  <p>{reversedOffer.points}</p>
                  <p>{reversedOffer.offer_name}</p>
                  <p>{reversedOffer.offer_id}</p>
                  <p>{reversedOffer.status}</p>
                  <p
                    className='ip clickable'
                    onClick={() =>
                      window.open(
                        `https://www.ipqualityscore.com/free-ip-lookup-proxy-vpn-test/lookup/${reversedOffer?.user_ip}`,
                        '_blank'
                      )
                    }
                  >
                    {reversedOffer?.user_ip}
                  </p>
                </div>
              ))}
        </div>

        <Pages
          loadMorePages={dynamicallyLoadMorePages}
          maxPages={Math.ceil(reversedOffers?.length / 8)}
          curpage={fakePage}
          setPage={setFakePage}
        />
      </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table {
                    overflow-x: auto;
                }

                .table-header, .table-row {
                    background-color: var(--dark-background);
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                    min-width: 675px;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;

                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                select {
                    background: var(--secondary-background);
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    height: 100%;
                    padding: 6px;
                    text-align: center;
                }
                
                .search {
                    display: flex;
                    margin-bottom: 15px;
                    height: 50px;
                    gap: 15px;
                }

                .search input {
                    border: unset;
                    outline: unset;
                    width: 100%;
                    color: white;
                    background-color: var(--secondary-background);
                    padding: 0px 10px;
                    border-radius: 6px;
                }

                .search button {
                    border: unset;
                    outline: unset;
                    width: 150px;
                    color: white;
                    background-color: var(--highlight-color);
                    border-radius: 6px;
                    cursor: pointer;
                }

                .green {
                    color: #4FAC7C;
                }

                .blue {
                    color: var(--highlight-color);
                }

                .clickable {
                    cursor: pointer;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                }

                .name p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .name:hover {
                    color: var(--highlight-color);
                }
                
                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            `}</style>
        </>
    )
}

export default Reversals
