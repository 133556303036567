import { useState, useEffect } from 'react';

const Chargebacks = () => {
    const [chargebacks, setChargebacks] = useState([]);
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0);
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0);

    useEffect(() => {
        async function getChargebacks() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/user/reversals`, { credentials: 'include' });
                const data = await res.json();
                setChargebacks(data);
            } catch (e) {
                setChargebacks([]);
                return;
            }
        }
        getChargebacks();
    }, [realPage]);

    return (
        <>
            <div className='chargebacks-container'>
                <p className='title'>Chargebacks</p>

                <table>
                    <thead>
                        <tr className='blue'>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Wall</th>
                            <th>Diamonds</th>
                            
                        </tr>
                    </thead>

                    <tbody>
                        {Array.isArray(chargebacks) &&
                            chargebacks.map((chargeback, index) => (
                                <tr className='row' key={chargeback._id}>
                                    <td>{chargeback._id}</td>
                                    <td>{chargeback.chargeback_name}</td>
                                    <td>{chargeback.wall}</td>
                                    <td>{chargeback.points?.toFixed(0)}</td>
                                    
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <style jsx>{`
                .chargebacks-container {
                    width: 100%;
                    background-color: var(--dark-background);
                    padding: 15px;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                table {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }

                tr {
                    width: 100%;
                    display: flex;
                    margin-top: 15px;
                    min-height: 30px;
                    height: 30px;
                    min-height: 30px;
                    border-radius: 6px;
                }

                td, th {
                    flex: 1;
                    text-align: center;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .row {
                    background-color: var(--secondary-background);
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
                }

                .green {
                    color: #4FAC7C;
                }

                .blue {
                    color: var(--highlight-color);
                }

                .clickable {
                    cursor: pointer;
                }
            `}</style>
        </>
    );
};

export default Chargebacks;
