import { useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { backendRequest } from '../components/api'

const Affiliate = () => {

    let { code } = useParams() 
    const history = useNavigate()

    useEffect(() => {
        async function redeemCode() {
            try {
                backendRequest('/affiliates/redeem', 'POST', {code})

                history('/earn')
            } catch (e) {
                return
            }
        }

        localStorage.setItem(
            'affiliate-code',JSON.stringify({timestamp: Date.now(),code: code,})
        )
        
        if (code) {
            redeemCode()
        } else {
            history('/earn')
        }
    }, [code])

    return (null)
}

export default Affiliate