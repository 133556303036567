import React, { useRef, useEffect } from 'react';

const YouTubePlayer = ({ videoId, play }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    const onPlayerReady = (event) => {
      // Lock player controls (disable keyboard controls and video progress bar)
      event.target.setOption('disablekb', true);
      event.target.setOption('controls', false);

      if (play) {
        event.target.playVideo();

        // Request fullscreen mode when the video starts playing
        const youtubePlayerContainer = document.getElementById('youtube-player');
        if (youtubePlayerContainer) {
          youtubePlayerContainer.requestFullscreen();
        }
      }
    };

    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        // Video has ended, exit fullscreen mode
        document.exitFullscreen();
      }
    };

    if (window.YT) {
      // If the YouTube API is already loaded, create the player
      createPlayer();
    } else {
      // Otherwise, wait for the API to be ready and then create the player
      window.onYouTubeIframeAPIReady = createPlayer;
    }

    function createPlayer() {
      playerRef.current = new window.YT.Player('youtube-player', {
        videoId,
        playerVars: {
          modestbranding: 1,
          playsinline: 1,
          fs: 0, // Disable the default fullscreen button
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    }

    return () => {
        if (playerRef.current) {
          playerRef.current.destroy();
        }
      };
    }, [videoId, play]);
  
    const handleCloseClick = () => {
      document.exitFullscreen();
      const youtubePlayerContainer = document.getElementById('youtube-player-container');
      if (youtubePlayerContainer) {
        youtubePlayerContainer.remove();
      }
    };
  
    return (
      <div
        id="youtube-player-container"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999, // Set a high z-index for the player container
        }}
      >
        <div
          id="youtube-player"
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: 100, // Lower z-index for the player's content
          }}
        />
        <button
          onClick={handleCloseClick}
          style={{
            position: 'absolute',
            top: '60px',
            right: '10px',
            backgroundColor: 'transparent',
            color: '#47b4ff',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            zIndex: 9999, // Set a higher z-index for the close button
          }}
        >
         close X
        </button>
      </div>
    );
  };
  
  export default YouTubePlayer;