import { useState, useEffect } from 'react'

const Offers = () => {

    const [offers, setOffers] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

    useEffect(() => {
        async function getOffers() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/user/offers/`, { credentials: 'include' });
                const data = await res.json();
            
                setOffers(data)
            } catch (e) {
                setOffers([])
                return
            }
        }
        
        getOffers()
    }, [realPage])

    return (
        <>
            <div className='orders-container'>
                <p className='title'>Completed Offers</p>

                <table>
                    <thead>
                        <tr className='blue'>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Wall</th>
                            <th>Diamonds</th>
                            <th>Status</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        {Array.isArray(offers) && offers.map((offer, index) => (
                            <tr className='row' key={offer._id}>
                                <td>{offer._id}</td>
                                <td>{offer.offer_name}</td>
                                <td>{offer.wall}</td>
                                <td>{offer.points?.toFixed(0)}</td>
                                <td className={offer.status === 'completed' ? 'green' : 'red'}>{offer.status}</td>
                                <td>{new Date(offer.timestamp).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <style jsx>{`
                .orders-container {
                    width: 100%;

                    background-color: var(--dark-background);
                    padding: 15px;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                table {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }

                tr {
                    width: 100%;
                    display: flex;
                    margin-top: 15px;
                    min-height: 30px;
                    height: 30px;
                    min-height: 30px;
                    border-radius: 6px;
                }

                td, th {
                    flex: 1;
                    text-align: center;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .row {
                    background-color: var(--secondary-background);
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
                }

                .green {
                    color: #4FAC7C;
                }

                .red {
                    color: #ac4f4f;
                }

                .blue {
                    color: var(--highlight-color);
                }

                .clickable {
                    cursor: pointer;
                }
            `}</style>
        </>
    )
}

export default Offers