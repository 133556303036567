import React, { useState, useEffect } from "react";

function MonthCountdown() {
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = now.getUTCMonth();
        const lastDay = new Date(Date.UTC(year, month + 1, 0));
        const endOfMonth = new Date(Date.UTC(year, month, lastDay.getUTCDate(), 23, 59, 59)); // Setting the time to 23:59:59 UTC
        const diff = endOfMonth.getTime() - now.getTime();
  
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
  
        setTimeLeft(`${days}d ${hours}h ${minutes}m`);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);



    return <div>Time left this month's leaderboard: {timeLeft}</div>;
  }
  
  export default MonthCountdown;