import { useEffect, useState } from 'react'
import Pages from '../../all/pages'

const Logins = ({userid}) => {

    const [logins, setLogins] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

    useEffect(() => {
        async function getLogins() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/logins/${userid}`, { credentials: 'include' })
                const data = await res.json();
            
                setLogins(data)
            } catch (e) {
                return
            }
        }
        
        getLogins()
    }, [realPage, userid])

    const dynamicallyLoadMorePages = async () => {

        let currentTotalPages = Math.round(logins.length / 8)

        if (currentTotalPages - 3 === fakePage) {
            setRealPage(realPage + 1)

            const data = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/logins/${userid}`, { credentials: 'include' });
            setLogins([...logins, ...data])

        }
    }

    return (
        <>
            <div className='users-container'>
                <div className='table-header'>
                    <p>ID</p>
                    <p>Date</p>
                    <p>Location</p>
                    <p>IP</p>
                    <p>Mobile</p>
                    <p>Proxy</p>
                    <p>Fingerprint</p>
                </div>

                {Array.isArray(logins) && logins.slice(fakePage * 8, (fakePage + 1) * 8).map((login, index) => (
                    <div className='table-row' key={login._id}>
                        <p>{login._id}</p>
                        <p>{login.timestamp ? new Date(login.timestamp).toLocaleString() : null}</p>
                        <p>{login.countryCode ? login.countryCode + ', ' + login.city + ', ' + login.zip : 'Unknown'}</p>
                        <p className='ip' onClick={() => window.open(`https://www.ipqualityscore.com/free-ip-lookup-proxy-vpn-test/lookup/${login.ip}`, '_blank')}>{login.ip}</p>
                        <p>{login.mobile?.toString()}</p>
                        <p>{login.proxy?.toString()}</p>
                        <p>{login.fingerprint}</p>
                    </div>
                ))}

                <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(logins?.length / 8)} curpage={fakePage} setPage={setFakePage}/>

            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-header, .table-row {
                    background-color: var(--dark-background);
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;

                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                }

                .ip {
                    cursor: pointer;
                }

                .ip:hover {
                    color: var(--highlight-color);
                }
            `}</style>
        </>
    )
}

export default Logins