import { useEffect, useState, useRef } from 'react'
import Loader from '../all/loader'
import Message from '../chat/message'

import { backendRequestAndNotification } from '../api'

const Support = ({settings, ws, user}) => {

    const [tickets, setTickets] = useState([])
    const [message, setMessage] = useState('')

    const [messages, setMessages] = useState([])
    const [who, setWho] = useState(null)
    const [loaded, setLoaded] = useState(0)
    
    const endOfMessagesRef = useRef(null)

    useEffect(() => {
        async function getTickets() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/support/tickets`, { credentials: 'include' });
                const data = await res.json();
            
                setTickets(data)
            } catch (e) {
                return
            }
        }
        
        getTickets()
    }, [])

    useEffect(() => {
        if (who) {
            async function getMessages() {
                try {

                    setLoaded(0)

                    const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/support/history?who=${who}`, { credentials: 'include' });
                    const data = await res.json();
                
                    setLoaded(1)

                    setMessages(data)
                } catch (e) {
                    return
                }
            }

            getMessages()
        }
    }, [who])
    
    useEffect(() => {
        if (messages && loaded) {
            endOfMessagesRef.current.scrollIntoView()
        }
    }, [messages, loaded])

    useEffect(() => {
        if (ws) {
            ws.on(`admin-${who}`, (data) => {
                setMessages((chat) => [...chat, data])
            })
            ws.on(`support_ticket`, (data) => {

                let index = tickets.findIndex(ticket => ticket._id === data._id)
    
                if (index >= 0) {
                    let newTicket = tickets[index]

                    for (let key of Object.keys(data)) {
                        newTicket[key] = data[key]
                    }

                    setTickets([
                        ...tickets.slice(0, index),
                        newTicket,
                        ...tickets.slice(index + 1)
                    ])
                } else {
                    setTickets((tickets) => [data, ...tickets])
                }
            })
            ws.on('close_ticket', (data) => {
                let index = tickets.findIndex(ticket => ticket._id === data)

                if (data === who) {
                    setWho(null)
                }
    
                if (index >= 0) {
                    setTickets([
                        ...tickets.slice(0, index),
                        ...tickets.slice(index + 1)
                    ])
                }
            })
        }

        return () => {
            if (ws) {
                ws.off(`admin-${who}`)
                ws.off('support_ticket')
            }
        }
    }, [ws, setMessages, who, tickets, setTickets])

    const sendMessage = (message) => {
        if (user && ws && who) {
            ws.emit('send_message', {message: message, channel: 'support', ownerid: who})
            setMessage('')
        }
      }
  
    const handleKeyPress = (e, message) => {
        if (e.key === 'Enter') {
          sendMessage(message)
        }
    }

    return (
        <>
            <div className='support-container'>
                <div className='users'>
                    {Array.isArray(tickets) && tickets.length > 0 ? tickets.map((ticket, index) => (
                        <div className='user' key={ticket._id + '-ticket'}>
                            <span>
                                <img src={ticket.avatar} alt=''/>
                                <p><a href={"https://gainify.net/admin/users/" + ticket._id + "/offers"}>{ticket.name}</a></p>
                            </span>

                            <span>
                                <button className='close' onClick={() => {
                                    backendRequestAndNotification('/admin/support/close', 'POST', {userid: ticket._id})
                                }}>
                                    Close
                                </button>

                                <button onClick={() => {
                                    setWho(who === ticket._id ? null : ticket._id)
                                }}>
                                    {who === ticket._id ? 'Exit' : 'Open'}
                                </button>
                            </span>

                            {ticket?.awaiting_reply && (
                                <div className='awaiting'/>
                            )}
                        </div>
                    )) : (
                        <p>There are no active tickets.</p>
                    )}
                </div>

                {who && (
                    <div className='chat'>
                        <div className='messages'>
                            {loaded ? (
                                <>
                                    <span className={loaded === 1 ? 'animate' : ''} onAnimationEnd={() => setLoaded(2)}>
                                        {Array.isArray(messages) && messages.map((message, index) => ( // name, level, avatar, message
                                            <Message message={message.message} rank={message.rank} name={message.username} level={message.level} avatar={message.avatar} key={message._id}/>
                                        ))}
                                    </span>
                                </>
                            ) : (
                                <div className='unloaded'>
                                    <Loader/>
                                </div>
                            )}

                            <span ref={endOfMessagesRef}/>
                        </div>
                        
                        <div className='input-wrapper'>
                            <input type='text' placeholder='Type message here...' value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => handleKeyPress(e, message)}/>
                        </div>
                    </div>
                )}
            </div>

            <style jsx>{`
                .support-container {
                    width: 100%;
                    display: flex;
                    gap: 30px;
                }

                .users {
                    display: flex;
                    flex-direction: column;
                    width: 325px;
                    min-width: 325px;
                    gap: 25px;
                }

                .user {
                    width: 100%;
                    height: 50px;
                    
                    background: var(--dark-background);
                    border-radius: 12px;

                    display: flex;
                    justify-content: space-between;
                    padding: 0px 10px;
                    align-items: center;

                    position: relative;
                }

                .awaiting {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    background-color: #eb3d3f;
                    border-radius: 50%;

                    height: 15px;
                    width: 15px;
                }

                .user img {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }

                .user span {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }

                .user button {
                    width: 75px;
                    height: 30px;
                    color: white;
                    
                    background: #007BFF;
                    border-radius: 8px;   

                    border: unset;
                    outline: unset;

                    cursor: pointer;
                    user-select: none;

                    font-size: 600;
                }

                .close {
                    background-color: #eb3d3f !important;
                }

                .chat {
                    width: 460px;
                    height: 750px;
                    
                    background: var(--dark-background);
                    border-radius: 12px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    gap: 15px;

                    padding: 0px 15px 15px 15px;
                }

                .messages {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;

                    overflow-y: scroll;
                    overflow-x: hidden;
                }

                .messages::-webkit-scrollbar {
                    display: none;
                }

                .input-wrapper {
                    width: 100%;
                    min-height: 50px;
                    height: 50px;

                    border-radius: 8px;
                    background-color: var(--secondary-background);

                    margin-top: auto;
                }

                .input-wrapper input {
                    width: 100%;
                    height: 100%;
                    background-color: unset;
                    border: unset;
                    outline: unset;

                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    color: white;
                    font-size: 12px;

                    box-sizing: border-box;
                    padding: 0px 10px;
                }

                .input-wrapper input::placeholder {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    color: rgba(223, 226, 239, 0.25);
                    font-size: 12px;
                }

                @media only screen and (max-width: 1100px) {
                    .support-container {
                        flex-direction: column;
                    }

                    .users, .chat {
                        width: 100%;
                    }
                }
            `}</style>
        </>
    )
}

export default Support
