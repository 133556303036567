const Slider = ({active, click}) => {
    return (
        <>
            <div className={'slider ' + (active ? 'active' : '')} onClick={click}>
                <div className='dot'/>
            </div>

            <style jsx>{`
                .slider {
                    width: 40px;
                    height: 20px;
                    background: var(--secondary-background);
                    border-radius: 2525px;
                    position: relative;
                    transition: all .2s;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 0px 5px;
                }

                .slider.active {
                    background: #2A5C44;
                }

                .dot {
                    background: #5b1d03;
                    border-radius: 50%;
                    height: 16px;
                    width: 16px;
                    transition: all .2s;
                }

                .slider.active .dot {
                    background: #388E65;
                    margin-left: calc(50%);
                }
            `}</style>
        </>
    )
}

export default Slider