import React, { useEffect } from 'react';
import Bitcoin from '../shop/cashout/bitcoin';
import Chat from '../chat/chatcontainer2';
import VConsole from 'vconsole';
import Wheel from './wheel'
import MonthCountdown from '../leaders/monthlycountdown';
const Test = ({ user, ws }) => {
  useEffect(() => {
    // Your useEffect logic here
    const vConsole = new VConsole({ theme: 'dark' });
  }, []);

  return (
    <div>
      <MonthCountdown />
           <Wheel />
      <div style={{width:"330px"}}>
   
     {/*} <Chat ws={ws} user={user} show={true} setShow={true} />*/}
      </div>
      <p>test</p>
      <Bitcoin img="/companies/ltc.svg" />
    </div>
  );
};

export default Test;