import Slider from '../all/slider';

const Settings = ({settings, ws, user}) => {

    const updateSettings = (key, value) => {
        fetch(process.env.REACT_APP_SERVER_URL + `/admin/settings/update`, {
            method: 'POST',
            body: JSON.stringify({key, value}),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
    }

    return (
        <>
            <div className='settings-container'>
                {settings ? (
                    <>
                        <div className='columns'>
                            <div className='column'>
                                <p className='section'>General</p>

                                <span className='option'>
                                    <p>Maintenance</p>
                                    <Slider active={settings.maintenance} click={() => updateSettings('maintenance', !settings.maintenance)}/>
                                </span>

                                <span className='option'>
                                    <p>Chat</p>
                                    <Slider active={settings.chat} click={() => updateSettings('chat', !settings.chat)}/>
                                </span>
                                
                                <span className='option'>
                                    <p>BuxBot</p>
                                    <Slider active={settings.bot} click={() => updateSettings('bot', !settings.bot)}/>
                                </span>
                            </div>

                            

                            <div className='column'>
                                <p className='section'>Walls</p>

                                <span className='option'>
                                    <p>AyetStudios</p>
                                    <Slider active={settings.ayet} click={() => updateSettings('ayet', !settings.ayet)}/>
                                </span>

                                <span className='option'>
                                    <p>Monlix</p>
                                    <Slider active={settings.monlix} click={() => updateSettings('monlix', !settings.monlix)}/>
                                </span>

                                <span className='option'>
                                    <p>Adgate</p>
                                    <Slider active={settings.adgate} click={() => updateSettings('adgate', !settings.adgate)}/>
                                </span>

                                <span className='option'>
                                    <p>Adscend</p>
                                    <Slider active={settings.adscend} click={() => updateSettings('adscend', !settings.adscend)}/>
                                </span>

                                <span className='option'>
                                    <p>Bitlabs</p>
                                    <Slider active={settings.bitlabs} click={() => updateSettings('bitlabs', !settings.bitlabs)}/>
                                </span>

                                <span className='option'>
                                    <p>Timewall</p>
                                    <Slider active={settings.timewall} click={() => updateSettings('timewall', !settings.timewall)}/>
                                </span>

                                <span className='option'>
                                    <p>Lootably</p>
                                    <Slider active={settings.lootably} click={() => updateSettings('lootably', !settings.lootably)}/>
                                </span>

                                <span className='option'>
                                    <p>Cheddar TV</p>
                                    <Slider active={settings.cheddartv} click={() => updateSettings('cheddartv', !settings.cheddartv)}/>
                                </span>

                                <span className='option'>
                                    <p>CPX Research</p>
                                    <Slider active={settings.cpx} click={() => updateSettings('cpx', !settings.cpx)}/>
                                </span>

                                <span className='option'>
                                    <p>Adgem</p>
                                    <Slider active={settings.adgem} click={() => updateSettings('adgem', !settings.adgem)}/>
                                </span>

                                <span className='option'>
                                    <p>Revenue Universe</p>
                                    <Slider active={settings.revu} click={() => updateSettings('revu', !settings.revu)}/>
                                </span>

                                <span className='option'>
                                    <p>Torox</p>
                                    <Slider active={settings.torox} click={() => updateSettings('torox', !settings.torox)}/>
                                </span>

                                <span className='option'>
                                    <p>InBrain</p>
                                    <Slider active={settings.inbrain} click={() => updateSettings('inbrain', !settings.inbrain)}/>
                                </span>

                                <span className='option'>
                                    <p>MMWall</p>
                                    <Slider active={settings.mmwall} click={() => updateSettings('mmwall', !settings.mmwall)}/>
                                </span>

                                <span className='option'>
                                    <p>Notik</p>
                                    <Slider active={settings.notik} click={() => updateSettings('mmwall', !settings.notik)}/>
                                </span>

                                <span className='option'>
                                    <p>Theorem</p>
                                    <Slider active={settings.theorem} click={() => updateSettings('theorem', !settings.theorem)}/>
                                </span>

                                <span className='option'>
                                    <p>Gainify Rewards</p>
                                    <Slider active={settings.rewards} click={() => updateSettings('rewards', !settings.rewards)}/>
                                </span>
                            </div>

                            <div className='column'>
                                <p className='section'>Withdraw</p>

                                <span className='option'>
                                    <p>PayPal</p>
                                    <Slider active={settings.paypal} click={() => updateSettings('paypal', !settings.paypal)}/>
                                </span>

                                <span className='option'>
                                    <p>LTC</p>
                                    <Slider active={settings.ltc} click={() => updateSettings('ltc', !settings.ltc)}/>
                                </span>

                                <span className='option'>
                                    <p>BTC</p>
                                    <Slider active={settings.btc} click={() => updateSettings('btc', !settings.btc)}/>
                                </span>

                                <span className='option'>
                                    <p>SOL</p>
                                    <Slider active={settings.sol} click={() => updateSettings('sol', !settings.sol)}/>
                                </span>

                                <span className='option'>
                                    <p>BCH</p>
                                    <Slider active={settings.bch} click={() => updateSettings('bch', !settings.bch)}/>
                                </span>

                                <span className='option'>
                                    <p>Visa</p>
                                    <Slider active={settings.visa} click={() => updateSettings('visa', !settings.visa)}/>
                                </span>

                                <span className='option'>
                                    <p>Roblox</p>
                                    <Slider active={settings.roblox} click={() => updateSettings('roblox', !settings.roblox)}/>
                                </span>

                                <span className='option'>
                                    <p>CSDeals</p>
                                    <Slider active={settings.csdeals} click={() => updateSettings('csdeals', !settings.csdeals)}/>
                                </span>

                                <span className='option'>
                                    <p>Discord</p>
                                    <Slider active={settings.nitro} click={() => updateSettings('nitro', !settings.nitro)}/>
                                </span>

                                <span className='option'>
                                    <p>Amazon</p>
                                    <Slider active={settings.amazon} click={() => updateSettings('amazon', !settings.amazon)}/>
                                </span>

                                <span className='option'>
                                    <p>Steam</p>
                                    <Slider active={settings.steam} click={() => updateSettings('steam', !settings.steam)}/>
                                </span>

                            </div>
                        </div>
                        <div className='columns'>
                            <div className='column'>
                                <p className='section'>Bonus</p>

                                <span className='option'>
                                    <p>AyetStudios bonus</p>
                                    <Slider active={settings.ayet_bonus} click={() => updateSettings('ayet_bonus', !settings.ayet_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Polltastic bonus</p>
                                    <Slider active={settings.polltastic_bonus} click={() => updateSettings('polltastic_bonus', !settings.polltastic_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Monlix bonus</p>
                                    <Slider active={settings.monlix_bonus} click={() => updateSettings('monlix_bonus', !settings.monlix_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Adgate bonus</p>
                                    <Slider active={settings.adgate_bonus} click={() => updateSettings('adgate_bonus', !settings.adgate_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Adscend bonus</p>
                                    <Slider active={settings.adscend_bonus} click={() => updateSettings('adscend_bonus', !settings.adscend_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Bitlabs bonus</p>
                                    <Slider active={settings.bitlabs_bonus} click={() => updateSettings('bitlabs_bonus', !settings.bitlabs_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Timewall bonus</p>
                                    <Slider active={settings.timewall_bonus} click={() => updateSettings('timewall_bonus', !settings.timewall_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Lootably bonus</p>
                                    <Slider active={settings.lootably_bonus} click={() => updateSettings('lootably_bonus', !settings.lootably_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Cheddar TV bonus</p>
                                    <Slider active={settings.cheddartv_bonus} click={() => updateSettings('cheddartv_bonus', !settings.cheddartv_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>CPX Research bonus</p>
                                    <Slider active={settings.cpx_bonus} click={() => updateSettings('cpx_bonus', !settings.cpx_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Adgem bonus</p>
                                    <Slider active={settings.adgem_bonus} click={() => updateSettings('adgem_bonus', !settings.adgem_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Revenue Universe bonus</p>
                                    <Slider active={settings.revu_bonus} click={() => updateSettings('revu_bonus', !settings.revu_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Torox bonus</p>
                                    <Slider active={settings.otorox_bonus} click={() => updateSettings('torox_bonus', !settings.torox_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>InBrain bonus</p>
                                    <Slider active={settings.inbrain_bonus} click={() => updateSettings('inbrain_bonus', !settings.inbrain_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>MMWall bonus</p>
                                    <Slider active={settings.mmwall_bonus} click={() => updateSettings('mmwall_bonus', !settings.mmwall_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Notik bonus</p>
                                    <Slider active={settings.notik_bonus} click={() => updateSettings('notik_bonus', !settings.notik_bonus)}/>
                                </span>

                                <span className='option'>
                                    <p>Theorem bonus</p>
                                    <Slider active={settings.theorem_bonus} click={() => updateSettings('theorem_bonus', !settings.theorem_bonus)}/>
                                </span>
                                <span className='option'>
                                    <p>Theorem bonus</p>
                                    <Slider active={settings.exclusive_bonus} click={() => updateSettings('exclusive_bonus', !settings.exclusive_bonus)}/>
                                </span>

                            </div>
                        </div>
                    </>
                ) : (<p>We're having issues fetching the site settings</p>)}
            </div>

            <style jsx>{`
                .settings-container {
                    width: 100%;
                }

                .columns {
                    width: 100%;

                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    grid-gap: 15px;
                }

                .column {
                    background-color: var(--dark-background);
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    padding: 15px;
                    gap: 15px;
                    // height: fit-content;
                }

                .section {
                    color: var(--highlight-color);
                }

                .option {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                p {
                    margin: unset;
                }
            `}</style>
        </>
    )
}

export default Settings