import { useEffect, useState } from 'react'
import Pages from '../../all/pages'

const Transactions = ({userid}) => {

    const [transactions, setTransactions] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

    useEffect(() => {
        async function getTransactions() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/transactions/${userid}`, { credentials: 'include' });
                const data = await res.json();
            
                setTransactions(data)
            } catch (e) {
                return
            }
        }
        
        getTransactions()
    }, [realPage, userid])

    const dynamicallyLoadMorePages = async () => {

        let currentTotalPages = Math.round(transactions.length / 8)

        if (currentTotalPages - 3 === fakePage) {
            setRealPage(realPage + 1)

            const data = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/transactions/${userid}`, { credentials: 'include' });
            setTransactions([...transactions, ...data])

        }
    }

    return (
        <>
            <div className='users-container'>
                <div className='table-header'>
                    <p>ID</p>
                    <p>Date</p>
                    <p>Amount</p>
                    <p>New Balance</p>
                    <p>Reason</p>
                </div>

                {Array.isArray(transactions) && transactions.slice(fakePage * 8, (fakePage + 1) * 8).map((tx, index) => (
                    <div className='table-row' key={tx._id}>
                        <p>{tx._id}</p>
                        <p>{new Date(tx.timestamp).toLocaleString()}</p>
                        <p>{tx.amount}</p>
                        <p>{tx.new_balance}</p>
                        <p>{tx.type}</p>
                    </div>
                ))}

                <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(transactions?.length / 8)} curpage={fakePage} setPage={setFakePage}/>
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-header, .table-row {
                    background-color: var(--dark-background);
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                }
            `}</style>
        </>
    )
}

export default Transactions