import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';

const Orders = () => {

    const [withdraws, setWithdraws] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

    const [viewing, setViewing] = useState(false)

    useEffect(() => {
        async function getWds() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/user/withdraws/`, { credentials: 'include' });
                const data = await res.json();
            
                setWithdraws(data)
            } catch (e) {
                setWithdraws([])
                return
            }
        }
        
        getWds()
    }, [realPage])

    const sendNotification = (type, message) => {
        let content = {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'colored',
        }

        if (!type) {
            return toast(message, content)
        }
    
        if (type === 'error') { 
            return toast.error(message, content)
        }

        if (type === 'success') {
            return toast.success(message, content)
        }
    }

    return (
        <>

            {viewing && (
                <div className='order-view' onClick={(e) => setViewing(null)}>
                    <div className='order-center' onClick={(e) => e.stopPropagation()}>
                        <p>Your Giftcard Code is</p>
                        <p>{viewing}</p>
                    </div>
                </div>
            )}
        
            <div className='orders-container'>
                <p className='title'>Withdrawals</p>

                <table>
                    <thead>
                        <tr className='blue'>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Diamonds</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Payment Reference #</th>
                        </tr>
                    </thead>

                    <tbody>
                        {Array.isArray(withdraws) && withdraws.map((withdraw, index) => (
                            <tr className='row' key={withdraw._id}>
                                <td>{withdraw._id}</td>
                                <td>{withdraw.type}</td>
                                <td>{withdraw.amount}</td>
                                <td className={withdraw.status === 'sent' ? 'green' : 'red'}>{withdraw.status}</td>
                                <td>{new Date(withdraw.timestamp).toLocaleDateString()}</td>
                                <td className='blue clickable' onClick={() => {

                                    if (withdraw.status !== 'sent') {
                                        sendNotification('error', `Your withdraw hasn't been sent out yet.`)
                                        return 
                                    }
                                    
                                    if (withdraw.type === 'LTC') {
                                        window.open(`https://blockchair.com/litecoin/transaction/${withdraw.code}`, '_blank')
                                        return
                                    }

                                    if (withdraw.type === 'BCH') {
                                        window.open(`https://blockchair.com/bitcoin-cash/transaction/${withdraw.code}`, '_blank')
                                        return
                                    }

                                    if (withdraw.type === 'SOL') {
                                        window.open(`https://solscan.io/tx/${withdraw.code}`, '_blank')
                                        return
                                    }

                                    setViewing(withdraw.code)

                                }}>View</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <style jsx>{`
                .orders-container {
                    width: 100%;

                    background-color: var(--dark-background);
                    padding: 15px;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                table {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }

                tr {
                    width: 100%;
                    display: flex;
                    margin-top: 15px;
                    min-height: 30px;
                    height: 30px;
                    min-height: 30px;
                    border-radius: 6px;
                }

                td, th {
                    flex: 1;
                    text-align: center;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .row {
                    background-color: var(--secondary-background);
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
                }

                .green {
                    color: #4FAC7C;
                }

                .red {
                    color: #ac4f4f;
                }

                .blue {
                    color: var(--highlight-color);
                }

                .clickable {
                    cursor: pointer;
                }

                .order-view {
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0,0,0,0.5);

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    position: fixed;

                    z-index: 1001;
                    top: 0;
                    left: 0;

                    animation: fadein .2s linear;
                }
                
                .order-center {
                    background-color: #471703;

                    width: 500px;
                    height: 200px;
                    border-radius: 12px;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    gap: 10px;
                }

                p {
                    margin: unset;
                }
                
                @keyframes fadein {
                    0% { opacity: 0; }
                    100% { opacity: 1; }
                }
            `}</style>
        </>
    )
}

export default Orders