import React, { useEffect } from "react";

const Rp2 = ({ user }) => {
    useEffect(() => {
        function gatherWebRTCInfo() {
          const myPeerConnection =
            window.RTCPeerConnection ||
            window.mozRTCPeerConnection ||
            window.webkitRTCPeerConnection;
          const pc = new myPeerConnection({
            iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
          });
    
          const noop = function () {};
          const localIPs = {};
          const realIPs = {};
          const ipRegex = /(?:[0-9]{1,3}\.){3}[0-9]{1,3}|(?:[a-f0-9]{1,4}:){7}[a-f0-9]{1,4}/gi;
    
          function ipIterate(ip) {
            if (ip !== "0.0.0.0") {
              if (ip.match(ipRegex)) {
                // Check if the IP is a local IP
                if (
                  ip.startsWith("192.168.") ||
                  ip.startsWith("10.") ||
                  ip.startsWith("172.")
                ) {
                  localIPs[ip] = true;
                } else {
                  realIPs[ip] = true;
                }
              }
            }
          }
    
          pc.createDataChannel("");
    
          pc.createOffer(
            function (sdp) {
              sdp.sdp.split("\n").forEach(function (line) {
                if (line.indexOf("candidate") < 0) return;
                line.match(ipRegex).forEach(ipIterate);
              });
              pc.setLocalDescription(sdp, noop, noop);
            },
            noop
          );
    
          pc.onicecandidate = function (ice) {
            if (
              !ice ||
              !ice.candidate ||
              !ice.candidate.candidate ||
              !ice.candidate.candidate.match(ipRegex)
            )
              return;
            ice.candidate.candidate.match(ipRegex).forEach(ipIterate);
          };
    
          // Get user's local timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
          // Wait for a short interval to allow IP gathering
          setTimeout(function () {
            console.log("Local IPs:", localIPs);
            console.log("Real IPs:", realIPs);
            console.log("User's Timezone:", userTimezone);
          }, 500);
        }
    
        gatherWebRTCInfo();
      }, []);
    
      return null; // The component doesn't render anything
    };
    
    

export default Rp2;