import { useEffect, useRef } from 'react'
import Message from './message'
import Loader from '../all/loader'
function linkify(text){
    var urlRegex =/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>'
    });
}
const MessagesContainer = ({messages, loaded, setLoaded, ws}) => {

    const endOfMessagesRef = useRef(null)
    
    useEffect(() => {
        endOfMessagesRef.current.scrollIntoView()
    }, [messages, loaded])

    return (
        <>
            <div className='messages-container'>

                {loaded ? (
                    <>
                        <span className={loaded === 1 ? 'animate' : ''} onAnimationEnd={() => setLoaded(2)}>
                            {Array.isArray(messages) && messages.map((message, index) => ( // name, level, avatar, message
                                <Message message={message.message} rank={message.rank} name={message.username} level={message.level} avatar={message.avatar} key={message._id + '-mine'}/>
                            ))}
                        </span>
                    </>
                ) : (
                    <div className='unloaded'>
                        <Loader/>
                    </div>
                )}

                <span ref={endOfMessagesRef}/>
            </div>

            <style jsx>
                {`
                    .messages-container {
                        width: 100%;
                        height: 100%;

                        display: flex;
                        flex-direction: column;

                        overflow-y: scroll;
                        overflow-x: hidden;
                        margin-bottom: 15px;
                    }

                    .animate {
                        animation-duration: .65s;
                        animation-name: slidein;
                        animation-timing-function: ease;
                    }

                    .unloaded {
                        height: 100%;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    @keyframes slidein {
                        from {
                            transform: translateX(330px);
                        }
                      
                        to {
                            transform: translateX(0px);
                        }
                    }

                    .messages-container::-webkit-scrollbar {
                        display: none;
                    }
                `}
            </style>
        </>
    )
}

export default MessagesContainer