import { faBitcoin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { backendRequestAndNotification } from '../../api/index'

const Bitcoin = ({ img }) => {
    const [amount, setAmount] = useState(0);
    const [address, setAddress] = useState('');
    const [fee, setFee] = useState(0);
    const [isFetchingFee, setIsFetchingFee] = useState(false);

    const fetchFee = async () => {
        setIsFetchingFee(true); // Start fetching fee
        try {
            const response = await fetch("https://api.gainify.net/withdraw/btcfees");
            const data = await response.json();
            setFee(data.fee);
        } catch (error) {
            console.error("Error fetching fee:", error);
        } finally {
            setIsFetchingFee(false); // Stop fetching fee (completed)
        }
    };

    useEffect(() => {
        fetchFee();

        const intervalId = setInterval(() => {
            fetchFee();
        }, 90000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <div className='withdraw-container'>
                <div className='information'>
                    <div className='image-container'>
                        <img src={img} alt='' draggable='false'/>
                    </div>
                    
                    <p>
                <span className='blue'>Transaction Fee:</span>{" "}
                {isFetchingFee ? (
                    <span>Loading...</span>
                ) : (
                    <span className='fee'>
                        {fee}                    </span>
                )}
            </p>
                </div>

                <div className='inputs'>
                    <div className='input'>
                        <p className='label'>You <span className='blue'>Withdraw Bitcoin</span></p>
                        <input placeholder='Withdraw Amount in BTC' value={amount} onChange={(e) => setAmount(e.target.value)}/>
                    </div>

                    <div className='input'>
                        <p className='label'>Your <span className='blue'>Bitcoin Address</span></p>
                        <input placeholder='Bitcoin Address' value={address} onChange={(e) => setAddress(e.target.value)}/>
                    </div>

                    <div className='horz'>
                        <p className='blue'>Minimum withdrawal amount for BTC is 2500 diamonds</p>
                        <div className='withdraw-button' onClick={() => {
                            backendRequestAndNotification(`/withdraw/btc`, 'POST', { address, amount: parseFloat(amount), fee: fee})
                        }}>
                            <p>Withdraw</p>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .withdraw-container {
                        height: 250px;
                        min-height: 250px;
                        width: 100%;
                        background-color: var(--dark-background);
                        border-radius: 8px;
                        padding: 15px;
                        font-weight: 600;

                        display: flex;
                        gap: 15px;

                        overflow: hidden;
                    }

                    .information {
                        width: 280px;
                        height: 100%;

                        display: flex;
                        flex-direction: column;
                    }

                    .information p {
                        margin: auto 0;
                        font-weight: 600;
                        display: flex;
                    }

                    .fee {
                        margin-left: auto;
                    }

                    .image-container {
                        height: 140px;
                        width: 280px;
                        background: linear-gradient(180deg, #A1A4C9 -3.68%, rgba(126, 128, 154, 0.7) 100%);
                        border-radius: 8px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    img {
                        width: 140px;
                        user-select: none;
                    }

                    .blue {
                        color: var(--highlight-color);
                    }

                    .input {
                        width: 100%;
                    }

                    .inputs {
                        width: 100%;
                        height: 100%;

                        display: flex;
                        flex-direction: column;
                    }

                    .label {
                        margin: 0px 0px 5px 0px;
                    }

                    .input input {
                        width: 100%;
                        height: 40px;
                        padding: 0px 15px;
                        border-radius: 6px;
                        outline: unset;
                        border: unset;
                        font-size: 14px;
                        background-color: var(--secondary-background);
                        color: white;
                        margin-bottom: 10px;
                    }

                    .horz {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        overflow: hidden;
                    }

                    .withdraw-button {
                        margin-left: auto;
                        height: 50px;
                        width: 170px;
                        background-color: var(--secondary-background-active);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #ef7d4d;
                        transition: all .2s;
                        cursor: pointer;
                    }

                    .withdraw-button:hover {
                        background-color: var(--secondary-background-active)
                    }

                    @media only screen and (max-width: 950px) {
                        .withdraw-container {
                            flex-direction: column;
                            min-height: unset;
                            height: fit-content;
                            align-items: center;
                            gap: 25px;
                        }

                        .information {
                            height: fit-content;
                        }

                        .inputs {
                            height: fit-content;
                        }
                    }

                    @media only screen and (max-width: 625px) {
                        .horz {
                            flex-direction: column;
                            align-items: center;
                        }

                        .withdraw-button {
                            width: 100%;
                        }
                    }
                `}
            </style>
        </>
    )
}

export default Bitcoin